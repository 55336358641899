$(document).ready(function(){
    function clearinputs(formid){
		$(formid+' .has-feedback').removeClass('has-error has-success'); // remove the error class
		$(formid+' .help-block').remove(); 
	}
    $('#login_form').submit(function(event) {
			clearinputs('#login_form');
            var that=$(this);
			// get the form data
			// there are many ways to get this data using jQuery (you can use the class or id also)
			var formData = that.serialize();

			// process the form
			$.ajax({
				type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
				url         : 'login.php', // the url where we want to POST
				data        : formData, // our data object
				dataType    : 'json', // what type of data do we expect back from the server
				encode      : true
			})
				// using the done promise callback
			.done(function(data) {

					// log data to the console so we can see
					console.log(data); 
                    var email_div=that.children("div:nth-child(1)");
                    var pwd_div=that.children("div:nth-child(2)");
                    var msg_block=$("#msg");
                    var help_block=that.children(".help-block");
					// here we will handle errors and validation messages
					if ( ! data.success) {
						
						
						// handle errors for email ---------------
						
						if (data.errors.email) {
							help_block.remove(); // remove the error text
							email_div.addClass('has-error'); // add the error class to show red input
							msg_block.append('<div class="help-block"><span class="text-danger">' + data.errors.email + '</span></div>'); // add the actual error message under our input
						}
						if (data.errors.pwd) {
							help_block.remove(); // remove the error text
							pwd_div.addClass('has-error'); // add the error class to show red input
							msg_block.append('<div class="help-block"><span class="text-danger">' + data.errors.pwd + '</span></div>'); // add the actual error message under our input
						    if(data.errors.register==1){
                                alertify.confirm("You haven't registered for an account yet. Would you like to do so now?",function(){
                                    location.href="../register/";   
                                },function(){
                                    
                                }).setHeader("Registration required");
                            }
                        }
						if (data.errors.conn) {
							help_block.remove(); // remove the error text
							msg_block.append('<div class="help-block"><span class="text-danger">' + data.errors.conn + '</span></div>'); // add the actual error message under our input
						}
					} else {
						help_block.remove(); // remove the error text
						// ALL GOOD! just show the success message!
						email_div.addClass('has-success');
						pwd_div.addClass('has-success');
						msg_block.append('<div class="help-block"><span class="text-success">' + data.message + '</span></div>');

						// usually after form submission, you'll want to redirect
						// window.location = '/thank-you'; // redirect a user to another page
						alertify.success(data.message); // for now we'll just alert the user
						//login(data.name,data.email);
						var timing=setTimeout(function(){
							location.href="../";
						},3000);
						
					}
			})	
        // using the fail promise callback
			.fail(function(data) {
                 alertify.alert(data.responseText).setHeader("Something Broke");
            });    

		// stop the form from submitting the normal way and refreshing the page
		event.preventDefault();
		
	});        
    $("#forgot form").on("submit",function(ev){
            var that=$(this);
            var formData = that.serialize();

			// process the form
			$.ajax({
				type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
				url         : that.attr("action"), // the url where we want to POST
				data        : formData, // our data object
				dataType    : 'json', // what type of data do we expect back from the server
			    encode      : true
			})
				// using the done promise callback
                .done(function(data) {
                    var timing;
                    if(!data.success){
                        that.find('.help-block').remove(); // remove the error text
                        // ALL GOOD! just show the success message!
                        that.find('div.form-group:nth-child(2)').addClass('has-error');
                        that.find('div.form-group:last div:first').append('<div class="help-block"><span class="text-danger">' + data.errors.email + '</span></div>');
                        timing=setTimeout(function(){
                            clearinputs('#contact > div > form');
                        },4000); /*    -----   */
                        alertify.error(data.errors.email);
                    }else{
                        that.find('.help-block').remove(); // remove the error text
                        // ALL GOOD! just show the success message!
                        that.find('.form-group').addClass('has-success');
                        that.find('div.form-group:last div:first').append('<div class="help-block"><span class="text-success">' + data.message + '</span></div>');
                        that.find('textarea').val("");
                        timing=setTimeout(function(){
                            clearinputs('#contact > div > form');
                        },4000); /*    -----   */
                        
                        
                        
                        if(!data.mailsent){
                            console.log("Mail not supported on this host.");
                            alertify.error("There was a problem handling your request.");
                            that.find('.help-block').remove(); // remove the error text
                            // ALL GOOD! just show the success message!
                            that.find('.form-group').addClass('has-success');
                            that.find('.form-group:nth-child(4)').removeClass('has-success').addClass('has-error');
                            that.find('div.form-group:last div:first').append('<div class="help-block"><span class="text-danger">Mail not supported on this host.</span></div>');
                            that.find('textarea').attr("placeholder","Mail not supported on this host.");
                            that.find('textarea').val("");
                            if(data.test){
                                alertify.alert(data.test).setHeader("Debug.");  
                            }
                                
                        }else{
                            alertify.success(data.message); //alert user
                            that.find('.help-block').remove(); // remove the error text
                            // ALL GOOD! just show the success message!
                            that.find('.form-group').addClass('has-success');
                            that.find('div.form-group:last div:first').append('<div class="help-block"><span class="text-success">' + data.message + '</span></div>');
                            that.find('textarea').val("");
                            
                        }
                        var timing2=setTimeout(function(){
                            $('#forgot').modal('hide');
                        },4000); /*    -----   */
                        that.find('textarea').focus(function(){
                            clearTimeout(timing2);
                        });     
                    }
                })
            // using the fail promise callback
                .fail(function(data) {

                    // show any errors
                    // best to remove for production
                        alertify.alert(data.responseText).setHeader("Something Broke");
                });
			// stop the form from submitting the normal way and refreshing the page
	    ev.preventDefault();
	});
  
    
    
});